import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import logo from '../images/stones-only.jpg'
const Header = ({ siteTitle }) => {
  const [show, setShow] = useState(false)



  return (
    <>
      <header className="mobile-header">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"></link>
        <Link
          to="/"
          style={{

            textDecoration: `none`,
          }}
        >
          <div className="logo-box-mobile">
            <img src={logo} width="80px" alt="good always logo" />
            <h1>Stones of Light <br /> Education Foundation</h1>
          </div>
        </Link>
        <span onClick={() => setShow(!show)} style={{ fontSize: '2em', margin: '0px' }} class="material-icons">
          menu
        </span>
      </header>
      {show ?
        <div className="mobile-options">
          <Link onClick={() => setShow(false)} className="header-link"
            to="/students"
            style={{
              textDecoration: `none`,
            }}>
            <span>Our Students</span>
          </Link>
          <Link onClick={() => setShow(false)} className="header-link"
            to="/progress"
            style={{
              textDecoration: `none`,
            }}>
            <span>Progress</span>
          </Link>
          <Link onClick={() => setShow(false)} className="header-link"
            to="/contact"
            style={{
              textDecoration: `none`,
            }}>
            <span>Contact Us</span>
          </Link>
          <Link onClick={() => setShow(false)} className="header-link"
            to="/partners"
            style={{
              textDecoration: `none`,
            }}>
            <span>Partners</span>
          </Link>
          <a className="header-link" target="_blank"
            href="https://goodalways.com"
            style={{

              textDecoration: `none`,
            }}
          >

            <span>Impact Investing</span>
          </a>

          <a className="header-link" target="_blank"
            href="https://goodalways.org"
            style={{

              textDecoration: `none`,
            }}
          >

            <span>Artisan Marketplace</span>
          </a>
          <a className="header-link" target="_blank"
            href="https://light-it-forward.org"
            style={{

              textDecoration: `none`,
            }}
          >

            <span>Light-It-Forward</span>
          </a>
          <Link onClick={() => setShow(false)} className="header-link"
            to="/archivedPhotos"
            style={{
              textDecoration: `none`,
            }}>
            <span>Archived Photos</span>
          </Link>



          {/* <a target="_blank" href="https://goodalways.net" style={{ display: 'flex', alignItems: 'center', marginRight: '1em', cursor: 'pointer', textDecoration: 'none' }} className="header-link">
            <span style={{ marginRight: '5px' }} class="material-icons">
              login
            </span>
            <span>Login</span>
          </a> */}
        </div>

        : ''
      }
      <header className="header">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"></link>

        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <div class="logo-box">
            <img src={logo} width="80px" alt="good always logo" />
            <h1>Stones of Light <br /> Education Foundation</h1>
          </div>
        </Link>
        <div style={{ display: 'flex', alignItems: 'center' }}>

          <Link className="header-link space"
            to="/students"
            style={{

              textDecoration: `none`,
            }}
          >
            <span>Our Students</span>
          </Link>

          <Link className="header-link space"
            to="/progress"
            style={{

              textDecoration: `none`,
            }}
          >
            <span>Progress</span>
          </Link>
          <Link className="header-link space"
            to="/partners"
            style={{

              textDecoration: `none`,
            }}
          >
            <span>Partners</span>
          </Link>

          <div class="dropdown space">
            <div class="dropbtn">More Info <span class="material-icons">
              expand_more
            </span></div>
            <div class="dropdown-content">
              <a className="header-link"
                href="/contact"
                style={{

                  textDecoration: `none`,
                }}
              >
                <span>Contact Us</span>
              </a>
              <a className="header-link"
                href="/partners"
                style={{

                  textDecoration: `none`,
                }}
              >
                <span>Partners</span>
              </a>



              <a className="header-link" target="_blank"
                href="https://goodalways.com"
                style={{

                  textDecoration: `none`,
                }}
              >

                <span>Impact Investing</span>
              </a>

              <a className="header-link" target="_blank"
                href="https://goodalways.org"
                style={{

                  textDecoration: `none`,
                }}
              >

                <span>Artisan Marketplace</span>
              </a>
              <a className="header-link" target="_blank"
                href="https://light-it-forward.org"
                style={{

                  textDecoration: `none`,
                }}
              >

                <span>Light-It-Forward</span>
              </a>
              <Link className="header-link space"
                to="/archivedPhotos"
                style={{

                  textDecoration: `none`,
                }}
              >
                <span>Archived Photos</span>
              </Link>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <a href="https://buy.stripe.com/7sIbJ7a3QaUD4IUaEE" target="_blank" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', marginRight: '1em', cursor: 'pointer' }} className="header-link">
            <button>Donate</button>
          </a>
          {/* <span style={{ marginRight: '1em' }}> | </span>
          <a target="_blank" href="https://goodalways.net" style={{ display: 'flex', alignItems: 'center', marginRight: '1em', cursor: 'pointer', textDecoration: 'none' }} className="header-link">
            <span style={{ marginRight: '5px' }} class="material-icons">
              login
            </span>
            <span>Login</span>
          </a> */}



        </div>
      </header>
    </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
